import config from '@config';

import { Template } from './_templateData';

export const FullmaktForBolagsrepresentant: Template = {
  id: 7,
  template: config.isProduction ? '9222115557519903710' : '8222115557379225689',
  actorType: 'licenseholder',
  fields: [
    {
      name: 'Vem ska ges fullmakt som bolagsrepresentant?',
      description:
        'Ange fullmaktstagare som ska ges rätten att bli bolagsrepresentant och därmed äger rätten att via sitt användarkonto genomföra tjänster för fullmaktsgivarens räkning för samtliga ägarskap där fullmaktsgivaren är företrädare.',
      fields: ['multiplePersonsWithSSN'],
    },
    {
      name: 'För vilket bolag gäller fullmakten?',
      description: 'Ange uppgifter för det bolag fullmakten ska gälla.',
      fields: ['juridicalPersonName', 'juridicalPersonOrgNumber'],
    },
    {
      name: 'Vem/vilka är firmatecknare för bolaget?',
      description:
        'Ange behörig firmatecknare för bolaget. Signering kommer att göras med BankID via tjänsten Scrive.',
      fields: ['multiplePersons'],
    },
  ],
  fieldOptions: [
    {
      multiplePersons: { showUser: true },
      multiplePersonsWithSSN: { output: 'authority', max: 3 },
      juridicalPersonName: { output: 'fullName' },
      juridicalPersonOrgNumber: { output: 'personalNumber' },
    },
  ],
  provider: 'scrive',
};
