import { setBaseUrl } from '@apis';
import { useCallback, useEffect, useRef, useState } from 'react';
import { mutateAsync, MutateAsyncAction, QueryConfig } from 'redux-query';
import superagent from 'superagent';

import useAbortController from './useAbortController';

export interface State {
  result?: 'failed' | 'timeout' | 'success';
  errorMessage?: string;
  qrCodePattern?: string;
}

export type ReturnValues<S extends State = State> = S & {
  retry: () => void;
};

type CollectResponse = superagent.Response;

const pollingInterval = 1000;

export const wrapCollectAction = <T>(
  queryConfig: QueryConfig<T>,
): MutateAsyncAction<T> => setBaseUrl(mutateAsync(queryConfig), 'webapi');

interface Props {
  collectCallback: () => Promise<CollectResponse>;
  onComplete?: (args: CollectResponse) => Promise<void> | void;
  onInit?: () => Promise<void>;
  onCancel?: () => Promise<void>;
  beforeEachCollect?: () => Promise<void> | void;
  proccessError?: (errorCode: number, errorMessage: string) => string;
}

export default function useBankIdCollect({
  collectCallback,
  onComplete,
  onInit,
  beforeEachCollect,
  proccessError,
  onCancel,
}: Props): ReturnValues {
  const abortController = useAbortController();
  const completed = useRef(false);
  const initialized = useRef(false);
  const { aborted } = abortController.signal;

  const [state, setState] = useState<State>({
    result: undefined,
  });

  /**
   * Init callback - creates bankId orderReference
   */
  const runInit = useCallback(async () => {
    if (onInit) {
      await onInit();
      initialized.current = true;
    }
  }, [onInit]);

  const intervalCallback = useCallback(async () => {
    if (state.result) {
      return;
    }

    if (beforeEachCollect) {
      await beforeEachCollect();
    }

    const response = await collectCallback();

    if (!response || aborted) {
      return;
    }

    const { body, status } = response;

    if (status >= 200 && status < 300 && body?.status === 'complete') {
      completed.current = true;
      // Successfull signing
      setState(prev => ({
        ...prev,
        result: 'success',
      }));

      if (onComplete) {
        await onComplete(response);
      }
    } else if (status >= 200 && status < 300 && body?.status === 'pending') {
      // Reset state when status is pending
      setState(prev => ({
        ...prev,
        result: undefined,
        errorMessage: undefined,
      }));
    } else if (status >= 400 || body?.status === 'failed') {
      let errorMessage =
        body?.localizedErrorMessage ||
        body?.errorMessage ||
        'Ett oväntat fel inträffade. Kontakta kundtjänst eller försök igen.';

      // Unicode hack
      // should be fixed in api instead
      // https://svenskgalopp.atlassian.net/browse/APP-838
      errorMessage = decodeURIComponent(JSON.parse(`"${errorMessage}"`));

      if (proccessError && body?.errorCode) {
        errorMessage = proccessError(body.errorCode, errorMessage);
      }

      setState(prev => ({
        ...prev,
        result: 'failed',
        errorMessage,
      }));
    }
  }, [
    aborted,
    beforeEachCollect,
    collectCallback,
    onComplete,
    proccessError,
    state.result,
  ]);

  // Retry calllback, restarts polling
  const retry = useCallback(async (): Promise<void> => {
    await onCancel?.();
    setState(prev => ({
      ...prev,
      result: undefined,
    }));

    await runInit();
  }, [onCancel, runInit]);

  // Run Init on mount
  useEffect(() => {
    runInit();
  }, [runInit]);

  useEffect(() => {
    intervalCallback(); // Initial polling

    const id = window.setInterval(intervalCallback, pollingInterval);

    const handleVisibilityChange = (): void => {
      if (!document.hidden) {
        intervalCallback(); // Trigger polling when the user returns from the BankID app or switches back to the tab
      }
    };

    // This event listener is needed for mobile because when the user switches to an external app (like BankID),
    // the tab becomes hidden, and polling stops. Once the user returns, we need to manually trigger polling
    // to ensure the status gets updated properly.
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      window.clearInterval(id);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [intervalCallback]);

  useEffect(
    () => () => {
      if (!completed.current && initialized.current) {
        onCancel?.();
      }
    },
    [onCancel],
  );

  return { ...state, retry };
}
