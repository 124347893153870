import { checkToDate } from '@components/Calendar';
import { Label } from '@components/Label';
import { RaceDay } from '@generated/raceinfo/src';
import useUser from '@hooks/useUser';
import Box from '@primitives/Box';
import { ActionLink } from '@primitives/Link';
import { Text } from '@primitives/Typography';
import { formatNonTz, formatTz } from '@utils/dateUtils';
import isTrainer from '@utils/isTrainer';
import { numToMonth } from '@utils/numToMonth';
import { numToShortMonth } from '@utils/numToShortMonth';
import React from 'react';
import styled from 'styled-components';

import { checkFromDate } from './utils/checkDate';

const StakeRaceInfoWrapper = styled(Box)`
  white-space: no-wrap;
  display: inline;
`;

const StakeRaceDay = styled(Text)`
  display: inline;
  white-space: nowrap;
`;

interface Props {
  item: RaceDay;
  mobile: boolean;
  type?: string;
}

const DayInfo = ({
  item,
  mobile,
  type = 'racecalendar',
}: Props): JSX.Element | null => {
  const user = useUser();

  const tracksArray = item?.track?.name?.split(',');
  const altName = tracksArray?.[1]?.substring(1);
  const trackName = (
    <Text as="span" sx={{ whiteSpace: 'nowrap' }}>
      {tracksArray?.[0]}
    </Text>
  );

  const showCTAEntryDate =
    item?.propositionDone && item?.entryDate && checkToDate(item.entryDate);

  const showCTAStartEntryDate =
    item?.propositionDone &&
    item?.weightDate &&
    checkFromDate(item.weightDate) &&
    item?.startEntryDate &&
    checkToDate(item.startEntryDate);

  const formattedMonth = !mobile
    ? numToShortMonth(item?.raceDate?.getMonth() + 1)
    : numToMonth(item?.raceDate?.getMonth() + 1)?.toLowerCase();

  const rowProps = {
    display: 'flex',
    justifyContent: 'flex-start',
    py: type === 'startpage' ? '10px' : '8px',
    pr: 3,
    pl: 1,
    borderBottomColor: type === 'startpage' ? 'gray2' : 'none',
    borderBottomStyle: type === 'startpage' ? 'solid' : 'none',
    borderBottomWidth: type === 'startpage' ? '1px' : '0px',
  } as const;

  if (item.typ === 'RACE') {
    return (
      <Box {...rowProps}>
        {type !== 'mountingcalendar' && (
          <Label color="blue">{altName || 'TÄVLINGSDAG'}</Label>
        )}
        <Text pl={2} fontWeight={'bold'} fontSize={['text', , 'text']}>
          {trackName}
        </Text>
        {item.timeForFirstStart && (
          <Text ml={1} fontSize={['text', , 'text']}>
            {formatTz(item.timeForFirstStart, 'HH:mm')}
          </Text>
        )}

        {type !== 'mountingcalendar' && (
          <>
            {isTrainer(user) && showCTAEntryDate && (
              <ActionLink ml={1} to={`/anmalan/${item.raceDayId}`}>
                Anmäl
              </ActionLink>
            )}

            {isTrainer(user) && showCTAStartEntryDate && (
              <ActionLink
                ml={1}
                to={`/minasidor/startanmalan/tavlingsdagar/${item.raceDayId}`}
              >
                Startanmäl
              </ActionLink>
            )}
          </>
        )}
      </Box>
    );
  }
  if (item.typ === 'DEPOSIT') {
    return (
      <Box {...rowProps}>
        <Label color="gray" mr={2}>
          INSATSLOPP
        </Label>

        <StakeRaceInfoWrapper>
          <StakeRaceDay fontSize={['text', , 'text']}>
            {item.depositRaceDepositNumber}
            {+item.depositRaceDepositNumber < 3 ? 'a' : 'e'} insats{' '}
          </StakeRaceDay>
          <Text
            fontWeight="bold"
            fontSize={['text', , 'text']}
            display="inline"
          >
            {item.depositRaceName}
          </Text>
        </StakeRaceInfoWrapper>
      </Box>
    );
  }

  if (item.typ === 'STARTENTRY') {
    return (
      <Box {...rowProps}>
        <div>
          {isTrainer(user) && showCTAStartEntryDate && (
            <ActionLink
              fontSize={['text', , 'text']}
              display="inline"
              mr={1}
              to={`/minasidor/startanmalan/tavlingsdagar/${item.raceDayId}`}
            >
              Startanmäl
            </ActionLink>
          )}

          <Text fontSize={['text', , 'text']} as="span">
            {!(isTrainer(user) && showCTAStartEntryDate)
              ? ' Startanmälan till '
              : 'till '}
            <b>
              {trackName} {formatNonTz(item?.raceDate, 'd')}{' '}
              {formattedMonth}{' '}
            </b>
            senast {formatTz(item.startEntryDate, 'HH:mm')}
          </Text>
        </div>
      </Box>
    );
  }
  if (item.typ === 'ENTRY') {
    return (
      <Box {...rowProps}>
        <div>
          {isTrainer(user) && showCTAEntryDate && (
            <ActionLink
              fontSize={['text', , 'text']}
              display="inline"
              mr={1}
              to={`/anmalan/${item.raceDayId}`}
            >
              Anmäl
            </ActionLink>
          )}
          <Text fontSize={['text', , 'text']} as="span">
            {!(isTrainer(user) && showCTAEntryDate) ? 'Anmäl till ' : 'till '}{' '}
            <b>
              {trackName} {formatNonTz(item.raceDate, 'd')}{' '}
              {formattedMonth}{' '}
            </b>
            senast {item?.entryDate && formatTz(item.entryDate, 'HH:mm')}{' '}
          </Text>
        </div>
      </Box>
    );
  }
  return null;
};
export default DayInfo;
