import SearchField from '@features/Search/SearchField';
import { SearchSportInfoTypeEnum } from '@generated/content/src';
import Box from '@primitives/Box';
import { Label } from '@primitives/Typography';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';

import { FormField } from './definitions/_fields';

type Props = {
  def: FormField;
  gender?: 'H' | 'S';
  onSelect: (horseId: number) => void;
};

interface FormState {
  query: string;
  type?: SearchSportInfoTypeEnum;
}

export function HorseSearch({ def, gender, onSelect }: Props): JSX.Element {
  const { setFieldValue } = useFormikContext();

  const [state, setState] = useState<FormState>({
    query: '',
  });

  return (
    <Box
      key={def.name}
      sx={{ flexGrow: 1, gridColumn: ['span 1', , 'span 3'] }}
    >
      <Box sx={{ width: ['100%', , '31.7%'] }}>
        <Label htmlFor={def.name}>Sök häst</Label>
        <SearchField
          type={SearchSportInfoTypeEnum.Horse}
          gender={gender}
          onChange={val => {
            setState(prev => ({ ...prev, query: val }));
          }}
          value={state.query}
          onSuggestionSelected={(e, { suggestion }) => {
            onSelect(suggestion.id);
            if (def.name === 'horseSearch' || def.name === 'mareSearch') {
              setFieldValue('horse', suggestion);
            } else if (def.name === 'stallionSearch') {
              setFieldValue('stallion', suggestion);
            }
          }}
        />
      </Box>
    </Box>
  );
}
