import { useGenerateNordicCertificatePdfLink } from '@apis';
import useNumericParams from '@hooks/useNumericParams';
import Error from '@primitives/Error';
import Loading from '@primitives/Loading';
import React, { useEffect } from 'react';

function GenerateNordicCertificateUrlAndRedirect(): JSX.Element {
  const { licenseId, fileId } = useNumericParams();

  const { data, loading, error, status } = useGenerateNordicCertificatePdfLink(
    licenseId,
    fileId,
  );

  useEffect(() => {
    if (data && data.url) {
      window.location.href = data.url;
    }
  }, [data]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <Error>
        ERROR! {status} {error}
      </Error>
    );
  }

  return null;
}

export default GenerateNordicCertificateUrlAndRedirect;
