import { useBankIdHorseCollect } from '@apis';
import { resetQuery } from '@main/state/queriesReducer';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Task from './enum/Task';
import SetTask from './type/SetTask';

interface Props {
  horseId: number;
  orderReference: string;
  qrInit: number;
  setTask: SetTask;
  autoStartToken: string;
}

export default function BankIdCollect({
  horseId,
  orderReference,
  qrInit,
  setTask,
  autoStartToken,
}: Props): JSX.Element | null {
  const { result, qrCodePattern } = useBankIdHorseCollect(
    horseId,
    orderReference,
    qrInit,
  );
  const dispatch = useDispatch();

  useEffect((): void => {
    const qrTime = Math.round((new Date().getTime() - qrInit) / 1000) || 1;
    if (result) {
      if (result === 'success') {
        dispatch(resetQuery(`registrationStatusOptions:${horseId}`));
        dispatch(resetQuery(`horse:${horseId}`));
        // dispatch(resetQuery(`horsesByOwnerships:*`));
        // dispatch(resetQuery(`trainerHorses:*`));

        setTask({
          type: Task.BankIdCollectSuccess,
          value: 'Sparat!',
        });
      } else {
        setTask({
          type: Task.BankIdCollectFailure,
          value: 'Något gick fel. Prova gärna igen...',
        });
      }
    } else if (qrCodePattern) {
      setTask({
        type: Task.BankIdCollectQrCode,
        value: { qrCodePattern, orderReference, autoStartToken },
      });
    } else if (qrTime > 1) {
      setTask({
        type: Task.BankIdCollectPending,
        value: { qrCodePattern, orderReference, autoStartToken },
      });
    }
  }, [
    autoStartToken,
    dispatch,
    horseId,
    orderReference,
    qrCodePattern,
    qrInit,
    result,
    setTask,
  ]);

  return null;
}
