import {
  CollectedRegistrationStatus,
  CollectedRegistrationStatusStatusEnum,
  collectWithAnimatedQrCodeRegistrationStatusUpdate,
  ErrorResponse,
} from '@generated/horses/src';
import useBankIdCollect, {
  ReturnValues,
  State as BaseState,
  wrapCollectAction,
} from '@hooks/useBankIdCollect';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MutateAsyncAction } from 'redux-query';
import superagent from 'superagent';

interface State extends BaseState {
  orderReference?: string;
  qrTime?: number;
  qrCodePattern?: string;
  autoStartToken?: string;
  errorMessage?: string;
}
interface CollectResponse extends superagent.Response {
  body: CollectedRegistrationStatus & ErrorResponse;
}

interface Entities {
  collectRegistrationStatusUpdate?: CollectedRegistrationStatus;
}

const collectAction = (
  horseId: number,
  orderReference: string,
  qrTime: number,
): MutateAsyncAction<Entities> =>
  wrapCollectAction(
    collectWithAnimatedQrCodeRegistrationStatusUpdate<Entities>(
      {
        horseId,
        orderReference,
        qrTime,
      },
      {
        transform: responseBody => ({
          collectRegistrationStatusUpdate: responseBody,
        }),
        update: {
          collectRegistrationStatusUpdate: (prev, next): typeof next => next,
        },
      },
    ),
  );

const useBankIdHorseCollect = (
  horseId: number,
  orderReference: string,
  qrInit: number,
): ReturnValues => {
  const [state, setState] = useState<State>({
    orderReference,
    qrCodePattern: undefined,
  });
  const dispatch = useDispatch();

  /**
   * Collect callback - polling for status
   */
  const runCollect = useCallback(async (): Promise<CollectResponse> => {
    const qrTime = Math.round((new Date().getTime() - qrInit) / 1000) || 1;
    if (qrTime > 30 && qrInit !== 0) {
      return {
        body: {
          errorMessage: 'Tiden för att signera har gått ut.',
          status: CollectedRegistrationStatusStatusEnum.FAILED,
        },
      } as unknown as CollectResponse;
    }

    const resp = (await dispatch(
      collectAction(horseId, orderReference, qrTime),
    )) as unknown as CollectResponse;

    if (resp.status === 200) {
      setState(prev => ({
        ...prev,
        qrCodePattern: resp.body.qrCodePattern,
      }));

      return resp;
    }

    // Dispatch usually doesn't return a response
    return resp;
  }, [dispatch, horseId, orderReference, qrInit]);

  const response = useBankIdCollect({
    collectCallback: runCollect,
  });

  return {
    ...state,
    ...response,
    result: state.result || response.result,
  };
};

export default useBankIdHorseCollect;
