import {
  getRegistrationStatusOptions,
  RegistrationStatusOptions,
} from '@generated/horses/src';
import { State } from '@main/store';
import { QueryConfig } from 'redux-query';

import { LazyApiResponse } from '../utils/types';
import useApiLazy from '../utils/useApiLazy';

type Result = RegistrationStatusOptions | undefined;

export interface Entities {
  registrationStatusOptions?: {
    [horseId: number]: Result;
  };
}

const getQuery = (horseId: number): QueryConfig<Entities> =>
  getRegistrationStatusOptions<Entities>(
    {
      horseId,
    },
    {
      transform: responseBody => ({
        registrationStatusOptions: {
          [horseId]: responseBody,
        },
      }),
      update: {
        registrationStatusOptions: (prev, next) => ({
          ...prev,
          ...next,
        }),
      },
    },
  );

const useGetRegistrationStatusOptions = (
  horseId: number,
): LazyApiResponse<Result, Entities> => {
  const query = getQuery(horseId);
  const selector = (state: State): Result =>
    state.entities?.registrationStatusOptions?.[horseId];

  const result = useApiLazy<Result>({
    query: {
      ...query,
      queryKey: `registrationStatusOptions:${horseId}`,
    },
    selector,
  });

  if (result.data?.registrationStatuses) {
    result.data.registrationStatuses = result.data.registrationStatuses.map(
      status => ({
        ...status,
        description:
          status.status === 'NO_LONGER_IN_FOOD_PRODUCTION'
            ? 'Tagen ur livsmedelsproduktion'
            : status.status === 'CASTRATE'
              ? 'Kastrerad'
              : status.description,
      }),
    );
  }

  return result;
};

export default useGetRegistrationStatusOptions;
