import { useIssueTemplate } from '@apis';
import FadedContent from '@components/ContentBlock/Faded';
import InfoBadge from '@components/InfoBadge';
import { PageLayout } from '@components/PageLayouts';
import useUser from '@hooks/useUser';
import Box from '@primitives/Box';
import { Link } from '@primitives/Link';
import { CircleInfo as InfoCircle } from '@styled-icons/fa-solid';
import isLoggedIn from '@utils/isLoggedIn';
import React from 'react';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import { IssueContent } from './content';
import ListIssue from './list';
import NewIssue from './new';
import { templateData } from './New/definitions/_templateData';

function IssueTemplateTitle({ templateId }: { templateId: number }): string {
  const {
    data: issueTemplate,
    loading: loadingIssueTemplate,
    error: errorIssueTemplate,
  } = useIssueTemplate(templateId ?? 0);

  if (loadingIssueTemplate || errorIssueTemplate) {
    return '';
  }

  return issueTemplate.title;
}

function Issues(): JSX.Element {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const user = useUser();
  const { search } = useLocation();

  const params = new URLSearchParams(search);

  const isSlug = !(pathname === '/tjanster' || pathname === '/tjanster/nytt');

  const templateId = params.get('templateId')
    ? parseInt(params.get('templateId'))
    : undefined;

  const template = templateId
    ? templateData.find(template => template.id === templateId)
    : undefined;

  return (
    <PageLayout
      pageSize="medium"
      headline={
        templateId
          ? IssueTemplateTitle({ templateId })
          : 'Tjänster & Blanketter'
      }
      noCard
      backLink={
        template ? { to: '/tjanster', text: 'Välj annan tjänst' } : undefined
      }
      contentTop={
        !template &&
        !isSlug && (
          <Box px={2} py={2} pb={0}>
            <FadedContent slug="tjanster" />
            {!isLoggedIn(user) && (
              <Box pt={4}>
                <InfoBadge
                  icon={InfoCircle}
                  color={'blue'}
                  message={
                    <>
                      <Link to="/login?dest=/tjanster">Logga in</Link> eller{' '}
                      <Link to="/skapa-konto">skapa konto</Link> enkelt via
                      BankID.
                    </>
                  }
                />
              </Box>
            )}
          </Box>
        )
      }
    >
      <Switch>
        <Route path={path} exact>
          <ListIssue />
        </Route>
        <Route path={`${path}/nytt`} exact>
          {template?.id && <NewIssue user={user} template={template} />}
        </Route>
        <Route path={`${path}/:content`}>
          <IssueContent />
        </Route>
      </Switch>
    </PageLayout>
  );
}

export default Issues;
