import config from '@config';
import auth from '@main/auth';
import { useCallback } from 'react';

type IssueRequest = {
  templateUrl: string;
  senderId: number;
  actorId?: number;
  actorType: string;
  firstName: string;
  lastName: string;
  email: string;
  template: string;
  values?: object;
};

export const useIssue = (): {
  create: (provider: string, args: IssueRequest) => Promise<Response>;
  list: (actorId?: number, closed?: boolean) => Promise<Response>;
} => {
  const create = useCallback(
    (provider: string, args: IssueRequest) =>
      fetch(
        `/${config.isProduction ? 'api' : 'api-test'}/issues/${provider}/send`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(args),
        },
      ),
    [],
  );

  const list = useCallback(
    (actorId?: number, closed: boolean = false) =>
      actorId
        ? fetch(
            `/${config.isProduction ? 'api' : 'api-test'}/issues/${actorId}/?closed=${closed ?? false}&t=${new Date().getTime()}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `${auth.token}`,
              },
            },
          )
        : fetch(
            `/${config.isProduction ? 'api' : 'api-test'}/issues/?t=${new Date().getTime()}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            },
          ),
    [],
  );

  return { create, list };
};
