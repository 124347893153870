import { Template } from './_templateData';

export const AnsokanOmInregistreringAvFolBetackningsresultat: Template = {
  id: 9,
  actorType: 'horse',
  newIssueButton: true,
  fields: [
    {
      name: 'Vilket sto betäcktes och vilket år?',
      description:
        'Sök fram det sto som betäcktes eller ange stoets uppgifter om det inte finns registrerat hos Svensk Galopp. Ange även vilket år betäckningen gjordes.',
      fields: [
        'horseSwitchButton',
        'horseSearchInfo',
        'mareSearch',
        'horseName',
        'horseBirthYear',
        'horseFather',
        'horseMother',
        'horseGrandFather',
        'br1',
        'betackningYear',
      ],
    },
    {
      name: 'Vilken hingst betäckte?',
      description:
        'Sök efter hingsten eller ange uppgifter för en ej registrerad häst.',
      fields: [
        'horseSwitchButtonStallion',
        'stallionInfo',
        'stallionSearch',
        'stallionName',
        'stallionBirthYear',
        'stallionFather',
        'stallionMother',
        'stallionGrandFather',
      ],
    },
    {
      name: 'Vad blev resultatet av betäckningen?',
      description:
        'Välj resultatet av betäckningen. Ange även eventuella övriga uppgifter som efterfrågas.',
      fields: [
        'betackningResult',
        'br2',
        'deathDate',
        'birthDate',
        'foalSex',
        'foalColor',
        'foalTwin',
        'foalBreeder',
        'foalCountry',
        'foalPlace',
        'foalPlaceNumber',
      ],
    },
    {
      hidden: 'values?.betackningResult !== "Föl"',
      name: 'Vilket namn ska hästen ha?',
      description:
        'Om du vill kan du uppge namnförslag. Ett hästnamn får innehålla högst 18 bokstäver, mellanslag inräknade.',
      fields: ['horseName1', 'horseName2', 'horseName3'],
    },
  ],
  fieldOptions: [
    {
      mareSearch: {
        hidden: 'values?.horseSwitchButton || values?.horse',
      },
      stallionSearch: {
        hidden: 'values?.horseSwitchButtonStallion || values?.stallion',
      },
      stallionName: { hidden: '!values?.horseSwitchButtonStallion' },
      stallionBirthYear: { hidden: '!values?.horseSwitchButtonStallion' },
      stallionFather: { hidden: '!values?.horseSwitchButtonStallion' },
      stallionMother: { hidden: '!values?.horseSwitchButtonStallion' },
      stallionGrandFather: { hidden: '!values?.horseSwitchButtonStallion' },
      horseName: { hidden: '!values?.horseSwitchButton' },
      horseBirthYear: { hidden: '!values?.horseSwitchButton' },
      horseFather: { hidden: '!values?.horseSwitchButton' },
      horseMother: { hidden: '!values?.horseSwitchButton' },
      horseGrandFather: { hidden: '!values?.horseSwitchButton' },
      br1: { hidden: '!values?.horseSwitchButton' },
      deathDate: {
        hidden:
          'values?.betackningResult !== "Fölet dog" && values?.betackningResult !== "Stoet dog" && values?.betackningResult !== "Tvillingar dog"',
        max: 'new Date()',
      },
      birthDate: {
        hidden: 'values?.betackningResult !== "Föl"',
        max: 'new Date()',
      },
      foalSex: { hidden: 'values?.betackningResult !== "Föl"' },
      foalColor: { hidden: 'values?.betackningResult !== "Föl"' },
      foalTwin: { hidden: 'values?.betackningResult !== "Föl"' },
      foalBreeder: { hidden: 'values?.betackningResult !== "Föl"' },
      foalCountry: { hidden: 'values?.betackningResult !== "Föl"' },
      foalPlace: { hidden: 'values?.betackningResult !== "Föl"' },
      foalPlaceNumber: { hidden: 'values?.betackningResult !== "Föl"' },
      horseName1: { max: 18, notRequired: true },
      horseName2: { max: 18 },
      horseName3: { max: 18 },
    },
  ],
  provider: 'email',
};
