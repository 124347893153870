import { useIssue } from '@apis';
import DataGrid from '@components/DataGrid';
import InfoBadge from '@components/InfoBadge';
import config from '@config';
import { europeanCountries } from '@features/Issues/New/definitions/_europeanCountries';
import formatDate from '@features/RaceInfo/Proposition/utils/formatDate';
// import Table from '@components/Table';
import { AuthenticatedUser } from '@generated/account/src';
import { Flex } from '@primitives/Box';
import Button, { ButtonLink } from '@primitives/Button';
import { Text } from '@primitives/Typography';
import { UserEdit } from '@styled-icons/fa-solid';
import { CircleInfo as InfoCircle } from '@styled-icons/fa-solid';
import { Minus, Plus } from '@styled-icons/fa-solid';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface Props {
  user: AuthenticatedUser;
  closed?: boolean;
}

const validFields = {
  // juridicalPersonName: 'Företagsnamn',
  juridicalPersonOrgNumber: 'Organisationsnummer',
  // juridicalPersonAddress: 'Adress',
  // address: 'Adress',
  // zipCodeCity: 'Postort',
  clearingNumber: 'Clearingnummer',
  accountNumber: 'Kontonummer',
  accountNumberPerson: 'Personkonto',
  accountNumberBankgiro: 'Bankgiro',
  bankName: 'Bank',
  horseBirthYear: 'Födelseår',
  horseSex: 'Kön',
  horseFather: 'Far',
  horseMother: 'Mor',
  horseGrandFather: 'Morfar',
  importCountry: 'Importland',
  horseLocation: 'Hästens vistelseort',
  horseName1: 'Namnförslag 1',
  horseName2: 'Namnförslag 2',
  horseName3: 'Namnförslag 3',
  horseOutOfTraining: 'Häst tagen ur träning, i träning t.o.m.',
  horseInTraining: 'Häst i träning fr.o.m.',
  stallion: 'Hingst',
  stallionName: 'Hingst',
  stallionBirthYear: 'Födelseår, hingst',
  stallionFather: 'Far, hingst',
  stallionMother: 'Mor, hingst',
  stallionGrandFather: 'Morfar, hingst',
  stallingCountry: 'Uppstallningsland, hingst',

  exportPersonName: 'Ägarens namn',
  exportPersonAddress1: 'Adressrad 1',
  exportPersonAddress2: 'Adressrad 2',
  exportPersonZipAndCity: 'Postnummer + Ort/Stad',
  exportPersonRegion: 'Region/Delstat',
  exportPersonCountry: 'Land',

  exportName: 'Uppstallningsplatsens namn',
  exportAddress1: 'Adressrad 1',
  exportAddress2: 'Adressrad 2',
  exportZipAndCity: 'Postnummer + Ort/Stad',
  exportRegion: 'Region/Delstat',
  exportCountry: 'Land',

  exportReason: 'Orsak',
  exportMareStatus: 'Stoets status',
  exportStallionName: 'Ska betäckas med hingst',
  exportFoalSex: 'Fölets kön',
  exportFoalFather: 'Fölets far',
  exportFoalNumber: 'Fölets regnr',
  exportFoalChipNr: 'Fölets microchipnr',
  exportStallionCoveredName: 'Betäckt med hingst',
  exportAuction: 'Auktionens namn',
  exportDate: 'Datum för utförsel',
  importDate: 'Datum för återinförsel',

  emailInvoice: 'E-post för faktura',

  betackningYear: 'Betäckningsår',
  betackningResult: 'Betäckningsresultat',
  deathDate: 'Dödsdatum',
  birthDate: 'Födelsedatum',
  foalColor: 'Fölets färg',
  foalSex: 'Fölets kön',
  foalTwin: 'Fölets tvilling',
  foalBreeder: 'Fölets uppfödare',
  foalCountry: 'Fölets födelseort / land (om utlandsfödd)',
  foalPlace: 'Uppstallningsplatsens namn',
  foalPlaceNumber: 'Anläggningsnummer',
};

export default function ListIssue({ user, closed }: Props): JSX.Element | null {
  const { search } = useLocation();
  const isNew = search === '?new=true';
  const [openSubRow, setOpenSubRow] = useState<number | null>(null);
  const [issues, setIssues] = useState([]);
  const { list } = useIssue();

  useEffect(() => {
    list(user.licenseId, closed).then(response => {
      response.json().then(data => {
        // Reverse order of issues
        data.issues.reverse();

        setIssues(data.issues);
      });
    });
  }, [list, closed, user.licenseId]);

  const getScriveUrl = (scriveData): string => {
    const documentJson =
      typeof scriveData.document_json === 'string'
        ? JSON.parse(scriveData.document_json)
        : scriveData.document_json;
    return config.isProduction
      ? `https://scrive.com${documentJson.parties[1]['api_delivery_url']}`
      : `https://api-testbed.scrive.com${documentJson.parties[1]['api_delivery_url']}`;
  };

  const getSignatories = (scriveData): string => {
    const documentJson =
      typeof scriveData.document_json === 'string'
        ? JSON.parse(scriveData.document_json)
        : scriveData.document_json;

    const total = documentJson.parties.reduce(
      (acc, party) => acc + (party.is_signatory ? 1 : 0),
      0,
    );
    const signed = documentJson.parties.reduce(
      (acc, party) => acc + (party.is_signatory && !!party.sign_time ? 1 : 0),
      0,
    );

    if (signed === total) {
      return `Signering slutförd, ${signed} av ${total} har signerat.`;
    }

    return `Signering återstår, ${signed} av ${total} har signerat.`;
  };
  const getText = useCallback((row, key) => {
    switch (key) {
      case 'stallion': {
        return row.original.values[key].name;
      }
      case 'stallionName': {
        return (
          row.original.values[key] +
          (row.original.values['stallionName_suffix']
            ? ` (${row.original.values['stallionName_suffix']})`
            : '')
        );
      }
      case 'exportStallionName': {
        return (
          row.original.values[key] +
          (row.original.values['exportStallionName_suffix']
            ? ` (${row.original.values['exportStallionName_suffix']})`
            : '')
        );
      }
      case 'exportStallionCoveredName': {
        return (
          row.original.values[key] +
          (row.original.values['exportStallionCoveredName_suffix']
            ? ` (${row.original.values['exportStallionCoveredName_suffix']})`
            : '')
        );
      }
      case 'exportFoalFather': {
        return (
          row.original.values[key] +
          (row.original.values['exportFoalFather_suffix']
            ? ` (${row.original.values['exportFoalFather_suffix']})`
            : '')
        );
      }
      case 'horseInTraining': {
        return formatDate(new Date(row.original.values[key]));
      }
      case 'horseOutOfTraining': {
        return formatDate(new Date(row.original.values[key]));
      }
      case 'exportDate': {
        return formatDate(new Date(row.original.values[key]));
      }
      case 'importDate': {
        return formatDate(new Date(row.original.values[key]));
      }
      case 'birthDate': {
        return formatDate(new Date(row.original.values[key]));
      }
      case 'deathDate': {
        return formatDate(new Date(row.original.values[key]));
      }
      case 'exportCountry': {
        return (
          europeanCountries.find(
            country => country.code === row.original.values[key],
          )?.swedishName ?? row.original.values[key]
        );
      }
      case 'exportPersonCountry': {
        return (
          europeanCountries.find(
            country => country.code === row.original.values[key],
          )?.swedishName ?? row.original.values[key]
        );
      }
      default: {
        return row.original.values[key]
          ? (row.original.values[key].toString() ?? '')
          : null;
      }
    }
  }, []);

  return (
    <DataGrid
      showMore
      data={issues}
      subRow={({ row }) =>
        (openSubRow === row.original.id && row.original.values) ||
        (row.index === 0 && isNew && !openSubRow) ? (
          <Flex display="flex-start" width={1}>
            {row.original.scrive_data && (
              <Flex
                flexDirection="column"
                verticalSpacing={3}
                alignItems="flex-start"
              >
                <InfoBadge
                  icon={InfoCircle}
                  color={'blue'}
                  message={<>{getSignatories(row.original.scrive_data)}</>}
                />
                <Button
                  as={'a'}
                  rel="nofollow"
                  target="_blank"
                  colorScheme="save"
                  href={getScriveUrl(row.original.scrive_data)}
                  icon={UserEdit}
                  display="flex"
                  sx={{
                    flexDirection: 'row-reverse',
                    paddingLeft: '10px',
                    paddingRight: '0px',
                    gap: '15px',
                  }}
                >
                  <Text>Öppna dokument i Scrive</Text>
                </Button>

                <br />
              </Flex>
            )}
            <table>
              <tbody>
                {Object.keys(validFields)
                  .filter(
                    field =>
                      Object.keys(row.original.values).includes(field) &&
                      row.original.values[field],
                  )
                  .map(key => (
                    <tr key={key}>
                      <th style={{ padding: 0 }}>{validFields[key]}:</th>
                      <td style={{ padding: 0, paddingLeft: 10 }}>
                        {getText(row, key)}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </Flex>
        ) : null
      }
      subRowWide={true}
      columns={[
        {
          Header: 'Ärende',
          accessor: 'issue',
          Cell: ({ row }) => (
            <ButtonLink
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '6px',
              }}
              onClick={() =>
                setOpenSubRow(
                  openSubRow === row.original.id ? null : row.original.id,
                )
              }
            >
              {openSubRow === row.original.id ||
              (row.index === 0 && isNew && !openSubRow) ? (
                <Minus size={12} />
              ) : (
                <Plus size={12} />
              )}
              {row.original.issue}
            </ButtonLink>
          ),
        },
        {
          Header: 'Namn',
          accessor: 'actor_id',
          Cell: ({ row }) =>
            row.original.actor
              ? row.original.actor.name
              : row.original.values?.horseName
                ? `${row.original.values?.horseName.toUpperCase()} ${
                    row.original.values?.horseName_suffix
                      ? `(${row.original.values?.horseName_suffix})`
                      : ''
                  }`
                : (row.original.values?.juridicalPersonName ??
                  row.original.values?.fullName ??
                  ''),
        },
        {
          Header: 'Skapat',
          accessor: 'date_time',
          Cell: ({ value }) => {
            const date = new Date(value);
            return (
              date.getFullYear().toString().substring(2) +
              (date.getMonth() + 1).toString().padStart(2, '0') +
              date.getDate().toString().padStart(2, '0')
            );
          },
        },
        closed
          ? {
              accessor: 'date_time_closed',
              Header: 'Avslutat',
              Cell: ({ value }) => {
                const date = new Date(value);
                return (
                  date.getFullYear().toString().substring(2) +
                  (date.getMonth() + 1).toString().padStart(2, '0') +
                  date.getDate().toString().padStart(2, '0')
                );
              },
            }
          : {
              Header: 'Status',
              Cell: ({ row }) => {
                let status =
                  row.original.closed === false ? 'Handläggs' : 'Avslutat';
                const scriveData = row.original.scrive_data;
                if (scriveData && scriveData.document_json) {
                  const documentJson =
                    typeof scriveData.document_json === 'string'
                      ? JSON.parse(scriveData.document_json)
                      : scriveData.document_json;
                  switch (documentJson.status) {
                    case 'pending':
                      status = 'Under signering';
                      break;
                    case 'rejected':
                      status = 'Avvisad signering';
                      break;
                  }
                }
                return status;
              },
            },
      ]}
    />
  );
}
