import config from '@config';

import { Template } from './_templateData';

export const AterkallelseAvFullmaktForBolagsrepresentant: Template = {
  id: 8,
  template: config.isProduction ? '9222115557519904090' : '8222115557379228108',
  actorType: 'licenseholder',
  fields: [
    {
      name: 'Vem ska fullmakten återkallas för?',
      description:
        'Ange fullmaktstagare som tidigare lämnad fullmakt som bolagsrepresentant ska återkallas för. Därmed fråntas rätten att via sitt användarkonto genomföra tjänster för fullmaktsgivarens räkning.',
      fields: ['multiplePersonsWithoutEmail'],
    },
    {
      name: 'För vilket bolag gäller fullmakten?',
      description: 'Ange uppgifter för det bolag fullmakten gäller.',
      fields: ['juridicalPersonName', 'juridicalPersonOrgNumber'],
    },
    {
      name: 'Vem/vilka är firmatecknare för bolaget?',
      description:
        'Ange behörig firmatecknare för bolaget. Signering kommer att göras med BankID via tjänsten Scrive.',
      fields: ['multiplePersons'],
    },
  ],
  fieldOptions: [
    {
      multiplePersons: { showUser: true },
      multiplePersonsWithoutEmail: { output: 'authority', max: 3 },
      juridicalPersonName: { output: 'fullName' },
      juridicalPersonOrgNumber: { output: 'personalNumber' },
    },
  ],
  provider: 'scrive',
};
