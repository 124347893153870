import { useGetOwner } from '@apis';
import Card from '@components/Card';
import InfoTable from '@components/InfoTable';
import { AuthenticatedUser } from '@generated/account/src';
import { Ownership } from '@generated/sportactors/src';
import { ArrowDownSVG } from '@primitives/Arrow';
import Box from '@primitives/Box';
import Button from '@primitives/Button';
import Icon from '@primitives/Icon';
import { H4 } from '@primitives/Typography';
import { Xmark as Times } from '@styled-icons/fa-solid';
import React, { useState } from 'react';
import { Row } from 'react-table';

import ShareHoldersForm from './ShareHoldersForm';

interface Props {
  row: Row<Ownership>;
  user: AuthenticatedUser;
}

export default function ListItem({ row, user }: Props): JSX.Element {
  const [open, setOpen] = useState(false);
  const { data } = useGetOwner(row.original.owner.id);

  return (
    <Card p={1} pb={0}>
      <H4 as="h2" color="gray6" mb={2} mt={2} fontWeight="bold">
        {row.original.owner.name}
      </H4>
      <InfoTable
        forceColumns={1}
        values={[
          {
            label: 'Företrädare',
            value: row.original.representative.name,
          },
          {
            label: 'Ägarfärger',
            value: data ? (data.dress ?? '') : '',
          },
        ]}
      />

      <Box mt={2} borderTop="solid 1px #E5DFD6" p={1} mx={-1}>
        <Box my={1}>
          <Button
            onClick={() => setOpen(!open)}
            block
            colorScheme={open ? 'secondaryAlt' : 'primary'}
          >
            {open ? 'Stäng' : 'Fördela andelar'}
            <Icon
              as={open ? Times : ArrowDownSVG}
              size={open ? 12 : 9.5}
              ml={1}
              mb="1px"
            />
          </Button>
        </Box>

        {open && (
          <Box bg="gray1" p={2} mt={2}>
            <ShareHoldersForm row={row} user={user} />
          </Box>
        )}
      </Box>
    </Card>
  );
}
