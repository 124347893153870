import { Flex } from '@primitives/Box';
import Button from '@primitives/Button';
import { Props as ModalProps } from '@primitives/Modal';
import React from 'react';

type FlexParameters = Parameters<typeof Flex>;

type Props = {
  action: string;
  buttonType?: 'button' | 'submit';
  cancel?: string;
  closeModal: ModalProps['closeModal'];
  colorScheme: 'primary' | 'delete';
  disabled?: boolean;
  loading?: boolean;
  onAction?: () => void | Promise<void>;
  wideMobileButtons?: boolean;
} & FlexParameters[0];

export default function Footer({
  action,
  buttonType = 'button',
  cancel = 'Avbryt',
  closeModal,
  colorScheme,
  disabled,
  loading,
  wideMobileButtons = false,
  wideButtons = false,
  onAction,
  ...props
}: Props): JSX.Element {
  const secondaryColorScheme =
    colorScheme === 'primary' ? 'secondary' : 'secondaryAlt';

  return (
    <Flex
      p={4}
      borderTopColor="gray2"
      borderTopStyle="solid"
      borderTopWidth={1}
      justifyContent="space-between"
      {...props}
      {...((wideMobileButtons || wideButtons) && {
        flexDirection:
          wideMobileButtons || wideButtons ? ['column', null, 'row'] : 'row',
      })}
    >
      {cancel && (
        <Button
          colorScheme={secondaryColorScheme}
          width={[
            wideMobileButtons || wideButtons ? '100%' : '48%',
            null,
            wideButtons ? '100%' : '48%',
          ]}
          onClick={closeModal}
          type="button"
          {...((wideMobileButtons || wideButtons) && {
            order: [1, null, wideButtons ? 1 : 0],
          })}
        >
          Avbryt
        </Button>
      )}
      {action && (
        <Button
          colorScheme={cancel ? colorScheme : secondaryColorScheme}
          disabled={disabled}
          loading={loading}
          onClick={onAction}
          type={buttonType}
          width={
            cancel
              ? [
                  wideMobileButtons || wideButtons ? '100%' : '48%',
                  null,
                  wideButtons ? '100%' : '48%',
                ]
              : '100%'
          }
          {...((wideMobileButtons || wideButtons) && {
            mb: [4, null, wideButtons ? 4 : 0],
            order: [0, null, wideButtons ? 0 : 1],
          })}
        >
          {action}
        </Button>
      )}
    </Flex>
  );
}
