import { RaceDay } from '@generated/raceinfo/src';

export interface Group {
  [key: string]: RaceDay[];
}

interface Props {
  raceDays: RaceDay[];
  month: number;
  showAll?: boolean;
  track?: string;
  onlyRaces?: boolean;
  onlyRacedays?: boolean;
}

const getRaceInfo = ({
  raceDays,
  month,
  showAll = true,
  onlyRacedays = false,
  track = 'alla',
  onlyRaces = false,
}: Props): Group => {
  const groupOfRaces: Group = raceDays.reduce((acc, current) => {
    // Removed the conversion from utc as the returned time is in local time.
    // const key = utcToZonedTime(current.showOnDate, 'Europe/Stockholm')
    //   .getDate()
    //   .toString();

    const key = current.showOnDate.getDate().toString();

    if (onlyRaces && current.typ !== 'RACE') {
      return acc;
    }

    if (onlyRacedays && current.typ !== 'RACE') {
      // Only show racedays
      return acc;
    }

    if (
      !showAll &&
      !['RACE', 'DEPOSIT', 'STARTENTRY', 'ENTRY'].includes(current.typ)
    ) {
      // In desktop: Skip other events when only showing days with events
      // TODO: Why do we have this?
      return acc;
    }

    if (track !== 'alla' && current.track.code !== track.toUpperCase()) {
      // Filter by track
      return acc;
    }

    acc[key] = acc[key] || [];

    if (current.showOnDate.getMonth() + 1 === month) {
      acc[key].push(current);
    }

    return acc;
  }, {} as Group);

  // Sort by priority OR if raceDate occurs before the other one
  Object.keys(groupOfRaces).forEach(key => {
    groupOfRaces[key].sort((a, b) => {
      if (a.prio === b.prio && a.raceDate && b.raceDate) {
        const aDate = new Date(a.raceDate);
        const bDate = new Date(b.raceDate);
        return aDate > bDate ? 1 : bDate > aDate ? -1 : 0;
      }
      return a.prio > b.prio ? 1 : b.prio > a.prio ? -1 : 0;
    });
  });

  return groupOfRaces;
};

export default getRaceInfo;
