import config from '@config';

import { Template } from './_templateData';

export const BankkontoGiroanmalan: Template = {
  id: 2,
  template: config.isProduction ? '9222115557519902808' : '8222115557379081751',
  actorType: 'licenseholder',
  fields: [
    {
      name: 'Fysisk eller juridisk person',
      description:
        'Välj om ärendet gäller dig själv som fysisk person eller om du agerar som firmatecknare för en juridisk person (bolag).',
      fields: [
        'personSwitchButton',
        'personInfo',
        'juridicalPersonName',
        'juridicalPersonOrgNumber',
        'juridicalPersonAddress',
        'juridicalPersonZipCode',
        'juridicalPersonCity',
        'juridicalPersonPhone',
        'juridicalPersonEmail',
      ],
    },
    {
      name: 'Var ska tillgodohavandet sättas in?',
      description:
        'Ange var du vill ha ditt tillgodohavande instatt. Plusgiro är ej tillåtet.',
      fields: [
        'accountTypeSwitchButton',
        'personAccountTypeSwitchButton',
        'clearingNumber',
        'accountNumber',
        'accountNumberPerson',
        'accountNumberBankgiro',
        'bankName',
      ],
    },
    {
      name: 'Vilka är dina vårdnadshavare?',
      hidden: '!isMinor(user)',
      description:
        'För minderårig person måste samtliga vårdnadshavare anges. Signering kommer att göras med BankID via tjänsten Scrive.',
      fields: ['multiplePersons'],
    },
    {
      name: 'Vem/vilka är firmatecknare för bolaget?',
      hidden: '!values?.personSwitchButton',
      description:
        'Ange behörig firmatecknare för bolaget. Signering kommer att göras med BankID via tjänsten Scrive.',
      fields: ['multiplePersons'],
    },
  ],
  fieldOptions: [
    {
      actorId: { hidden: true },
      personInfo: { hidden: 'values?.personSwitchButton' },
      juridicalPersonName: {
        hidden: '!values?.personSwitchButton',
        output: 'fullName',
      },
      juridicalPersonOrgNumber: {
        hidden: '!values?.personSwitchButton',
        output: 'personalNumber',
      },
      juridicalPersonAddress: {
        hidden: '!values?.personSwitchButton',
        output: 'address',
      },
      juridicalPersonZipCode: {
        hidden: '!values?.personSwitchButton',
        output: 'zipCodeCity',
        outputBefore: 'juridicalPersonCity',
      },
      juridicalPersonCity: {
        hidden: '!values?.personSwitchButton',
        output: 'zipCodeCity',
        outputAfter: 'juridicalPersonZipCode',
      },
      juridicalPersonPhone: {
        hidden: '!values?.personSwitchButton',
        output: 'phone',
      },
      juridicalPersonEmail: {
        hidden: '!values?.personSwitchButton',
        output: 'email',
      },
      accountTypeSwitchButton: { hidden: '!values?.personSwitchButton' },
      personAccountTypeSwitchButton: {
        hidden: 'values?.personSwitchButton',
      },
      clearingNumber: {
        hidden:
          'values?.accountTypeSwitchButton || values?.personAccountTypeSwitchButton',
      },
      accountNumber: {
        hidden:
          'values?.accountTypeSwitchButton || values?.personAccountTypeSwitchButton',
      },
      accountNumberBankgiro: {
        hidden:
          '!values?.personSwitchButton || !values?.accountTypeSwitchButton',
      },
      bankName: {
        hidden:
          'values?.accountTypeSwitchButton || values?.personAccountTypeSwitchButton',
      },
      multiplePersons: { showUser: '!isMinor(user)' },
      accountNumberPerson: {
        hidden:
          '!values?.personAccountTypeSwitchButton || values?.personSwitchButton',
      },
    },
  ],
  provider: 'scrive',
};
