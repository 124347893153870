import { useActor, useActorMutate, useUpdatePersonalInformation } from '@apis';
import { Input } from '@components/FormikComponents';
import FormLayout, { FormItem } from '@components/FormLayout';
import { AuthenticatedUser } from '@generated/authenticate/src';
import {
  ActorResource,
  GetActorTypeEnum,
  UpdateActorTypeEnum,
} from '@generated/content/src';
import { ChangeableUserInformation } from '@generated/user/src';
import useAuthenticationMethod from '@hooks/useAuthenticationMethod';
import { base, BaseProps } from '@primitives/base';
import Button from '@primitives/Button';
import Error from '@primitives/Error';
import Message from '@primitives/Message';
import hasRole from '@utils/hasRole';
import roleEnum from '@utils/roleEnum';
import { Formik, FormikErrors } from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  initialValues: ChangeableUserInformation;
  licenseId: number;
  changeSmsNumber: boolean;
  user: AuthenticatedUser;
}

export const HintText = styled.span<BaseProps>`
  font-style: italic;
  font-size: 0.8rem;
  padding-top: 0.2rem;
  ${base()}
`;

function FormWithLicensee({
  initialValues,
  licenseId,
  changeSmsNumber,
  user,
}: Props): JSX.Element {
  const { action, error } = useUpdatePersonalInformation(licenseId);
  const { data: actor } = useActor(GetActorTypeEnum.Trainer, licenseId);
  const { action: actionActor } = useActorMutate(
    UpdateActorTypeEnum.Trainer,
    licenseId,
  );

  const authMethod = useAuthenticationMethod();

  if (!actor) {
    return null;
  }

  const formvalues = {
    email: '',
    smsNumber: '',
    webPage: '',
    ...actor,
    ...initialValues,
  };

  // Check if any error exists
  const anyError = (
    errors: FormikErrors<ChangeableUserInformation & ActorResource>,
  ): boolean => Object.keys(errors).some(key => errors[key]);

  // Return error text as array of strings
  const getErrorText = (
    errors: FormikErrors<ChangeableUserInformation & ActorResource>,
  ): string[] =>
    Object.keys(errors).map(key => {
      if (errors[key]) {
        return errors[key];
      }
    });

  return (
    <Formik<ChangeableUserInformation & ActorResource>
      initialValues={formvalues}
      onSubmit={async (values, helpers) => {
        // Save user values if changed
        if (
          values.email !== formvalues.email ||
          values.webPage !== formvalues.webPage ||
          values.smsNumber !== formvalues.smsNumber
        ) {
          const { body, status } = await action({
            email: values.email,
            webPage: values.webPage,
            smsNumber: values.smsNumber || undefined,
          });

          if (status !== 204) {
            helpers.setFieldError(
              'all',
              body?.errorMessage || 'Ett oväntat fel inträffade',
            );
            helpers.setSubmitting(false);
            return;
          }
        }

        // Save actor values if changed
        if (
          actor &&
          (formvalues.activeSince !== values.activeSince ||
            formvalues.birthYear !== values.birthYear ||
            formvalues.residence !== values.residence ||
            formvalues.facebookLink !== values.facebookLink ||
            formvalues.instagramLink !== values.instagramLink ||
            formvalues.twitterLink !== values.twitterLink)
        ) {
          const { body, status } = await actionActor({
            activeSince: values.activeSince || undefined,
            birthYear: values.birthYear || undefined,
            residence: values.residence || undefined,
            facebookLink: values.facebookLink
              ? values.facebookLink.replace(/^(http(s)?:\/\/)/, '')
              : undefined,
            instagramLink: values.instagramLink
              ? values.instagramLink.replace(/^(http(s)?:\/\/)/, '')
              : undefined,
            twitterLink: values.twitterLink
              ? values.twitterLink.replace(/^(http(s)?:\/\/)/, '')
              : undefined,
          });

          if (status !== 200) {
            helpers.setFieldError(
              'all',
              body?.errorMessage || 'Ett oväntat fel inträffade',
            );
            helpers.setSubmitting(false);
            return;
          }
        }

        helpers.resetForm({ values, status: 'success' });
        setTimeout(() => {
          helpers.setStatus(undefined);
        }, 4000);
      }}
      validate={values => {
        const errors: Record<string, string> = {};

        if (authMethod !== 'BANKID') {
          const number = values?.smsNumber || '';

          const length = number.replace(/[^0-9]/g, '').length;

          if (length < 4 || length > 13 || number.length > 16) {
            errors['smsNumber'] =
              'SMS-nummer måste bestå av minst 4 och max 13 siffror.';
          }
        }

        if (
          values.email &&
          (!values.email.includes('@') || values.email.length > 100)
        ) {
          errors['email'] = 'E-postadressen är inte giltig.';
        }

        // Regex to validate web address, must start with http(s):// or www.
        const regexWebpage =
          /^(http(s)?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/;

        if (
          values.webPage &&
          (values.webPage.length > 200 || !regexWebpage.test(values.webPage))
        ) {
          errors['webPage'] = 'Felaktig webbadress.';
        }

        if (
          values.activeSince &&
          (values.activeSince < 1900 || values.activeSince > 2100)
        ) {
          errors['activeSince'] = 'Ange årtal med fyra siffror.';
        }

        if (
          values.birthYear &&
          (values.birthYear < 1900 || values.birthYear > 2100)
        ) {
          errors['birthYear'] = 'Ange årtal med fyra siffror.';
        }

        if (values.residence && values.residence.length > 30) {
          errors['residence'] = 'Max 30 tecken.';
        }

        // Regex for facebook
        const regexFacebook =
          /^(http(s)?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9_.\-/]{1,15}$/;
        if (values.facebookLink && !regexFacebook.test(values.facebookLink)) {
          errors['facebookLink'] = 'Facebook-länken är inte giltig.';
        }

        // Regex for twitter
        const regexInstagram =
          /^(http(s)?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9_.\-/]{1,15}$/;
        if (
          values.instagramLink &&
          !regexInstagram.test(values.instagramLink)
        ) {
          errors['instagramLink'] = 'Instagram-länken är inte giltig.';
        }

        // Regex for twitter
        const regexTwitter =
          /^(http(s)?:\/\/)?(www\.)?twitter\.com\/[a-zA-Z0-9_.\-/]{1,15}$/;
        const regexXTwitter =
          /^(http(s)?:\/\/)?(www\.)?x\.com\/[a-zA-Z0-9_.\-/]{1,15}$/;
        if (
          values.twitterLink &&
          !(
            regexTwitter.test(values.twitterLink) ||
            regexXTwitter.test(values.twitterLink)
          )
        ) {
          errors['twitterLink'] = 'X / Twitter-länken är inte giltig.';
        }

        return errors;
      }}
    >
      {({ isSubmitting, dirty, status, errors, touched }) => (
        <FormLayout variant="vertical">
          {authMethod !== 'BANKID' && (
            <FormItem maxWidth="400px">
              <Input
                name="smsNumber"
                label="SMS-nummer"
                type="text"
                disabled={!changeSmsNumber}
              />
              <HintText>
                För att kunna beställa temporärt lösenord via SMS.
              </HintText>
            </FormItem>
          )}

          {hasRole(user, roleEnum.Licensee) ? (
            <>
              <FormItem data-no-grow display="inline-block">
                Nedanstående uppgifter visas på{' '}
                <Link to={`/sportinfo/licensinnehavare/${licenseId}`}>
                  din profil i Sportinfo
                </Link>
              </FormItem>
              <FormItem maxWidth="400px">
                <Input name="email" label="E-post" type="text" />
              </FormItem>
              <FormItem maxWidth="400px">
                <Input name="webPage" label="Webbplats" type="text" />
                <HintText>Länk/URL</HintText>
              </FormItem>
              <FormItem maxWidth="400px">
                <Input
                  name="activeSince"
                  label="Aktiv sedan (årtal)"
                  type="number"
                />
                <HintText>Årtal med fyra siffror</HintText>
              </FormItem>
              <FormItem maxWidth="400px">
                <Input name="birthYear" label="Födelseår" type="number" />
                <HintText>Årtal med fyra siffror</HintText>
              </FormItem>
              <FormItem maxWidth="400px">
                <Input name="residence" label="Bostadsort" type="text" />
              </FormItem>
              <FormItem maxWidth="400px">
                <Input
                  name="facebookLink"
                  label="Facebook-profil"
                  type="text"
                  placeholder="facebook.com/<användarnamn>"
                />
                <HintText>Fullständig länk till din Facebook-profil</HintText>
              </FormItem>
              <FormItem maxWidth="400px">
                <Input
                  name="instagramLink"
                  label="Instagram-profil"
                  type="text"
                  placeholder="instagram.com/<användarnamn>"
                />
                <HintText>Fullständig länk till din Instagram-profil</HintText>
              </FormItem>
              <FormItem maxWidth="400px">
                <Input
                  name="twitterLink"
                  label="X / Twitter-profil"
                  type="text"
                  placeholder="twitter.com/<användarnamn>"
                />
                <HintText>
                  Fullständig länk till din X / Twitter-profil
                </HintText>
              </FormItem>
            </>
          ) : (
            <FormItem maxWidth="400px">
              <Input name="email" label="E-post" type="text" />
            </FormItem>
          )}
          <FormItem data-no-grow display="inline-block">
            <Button type="submit" loading={isSubmitting} disabled={!dirty}>
              Spara
            </Button>
            {status === 'success' && (
              <Message variant="success" ml={4} display="inline-block">
                Sparat!
              </Message>
            )}
          </FormItem>
          {error && <Error>{error}</Error>}
          {anyError(errors) && touched && (
            <Error>
              {getErrorText(errors).map((err, index) => (
                <p key={index}>{err}</p>
              ))}
            </Error>
          )}
        </FormLayout>
      )}
    </Formik>
  );
}

export default FormWithLicensee;
