import { useIssue } from '@apis';
import Alert from '@components/Alert';
import { DatePicker, Select } from '@components/FormikComponents';
import { Input as FormikInput } from '@components/FormikComponents';
import InfoBadge from '@components/InfoBadge';
import Step from '@components/Step';
import { AuthenticatedUser } from '@generated/account/src';
import { IssueTemplate } from '@generated/content/src';
import useIsMobile from '@hooks/useIsMobile';
import theme from '@main/theme';
import Box, { FadedBox, Flex } from '@primitives/Box';
import Button, { ButtonLink } from '@primitives/Button';
import { Checkbox } from '@primitives/Form';
import Input from '@primitives/Form/input';
import Icon from '@primitives/Icon';
import SwitchButton from '@primitives/SwitchButton';
import { H3, Text } from '@primitives/Typography';
import { Label } from '@primitives/Typography';
import { CircleInfo as InfoCircle } from '@styled-icons/fa-solid';
import isLoggedIn from '@utils/isLoggedIn';
import { Formik, FormikErrors } from 'formik';
import React, {
  Fragment,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Prompt } from 'react-router-dom';
import styled from 'styled-components';
import { ExclamationTriangle, ExternalLinkAlt } from 'styled-icons/fa-solid';

import { europeanCountries } from './definitions/_europeanCountries';
import { fields, FormField } from './definitions/_fields';
import { Template, templateData } from './definitions/_templateData';
import { HorseExportInfo } from './horseExportInfo';
import { HorseInfo } from './horseInfo';
import { HorseSearch } from './horseSearch';
import { PersonInfo } from './personInfo';
import { HorsesContext } from './withHorses';

interface FormProps {
  user: AuthenticatedUser;
  template: (typeof templateData)[number];
  issueTemplate: IssueTemplate;
}

type FormState = {
  template: number;
  [key: string]: string | number | undefined;
};

type SetFieldValueType = (
  field: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  shouldValidate?: boolean,
) => Promise<void | FormikErrors<FormState>>;

export const isMinor = (user: AuthenticatedUser): boolean => {
  const idNumber = user.userInfo.idNumber;
  const birthDate = new Date(
    `${idNumber.substring(0, 4)}-${idNumber.substring(4, 6)}-${idNumber.substring(6, 8)}T12:00:00.000Z`,
  );

  const diff = Date.now() - birthDate.getTime();
  const ageDate = new Date(diff);
  return Math.abs(ageDate.getUTCFullYear() - 1970) < 18;
};

const ExternalLink = styled(Text).attrs({
  display: 'flex',

  height: 'fit-content',
  width: 'fit-content',
  textAlign: 'right',
})``;

export function IssueFormBuilder({
  user,
  template,
  issueTemplate,
}: FormProps): JSX.Element | null {
  const [textOpen, setTextOpen] = useState(false);
  const isMobile = useIsMobile();

  const toggleLimit = isMobile ? 60 : 80;
  const { ownedHorses, trainedHorses, exportedHorses, userData } =
    useContext(HorsesContext);
  const [open, setOpen] = useState(false);
  const [horseId, setHorseId] = useState<number>(0);
  const [horseSearchId, setHorseSearchId] = useState<number>(0);
  const [stallionId, setStallionId] = useState<number>(0);
  const [personCount, setPersonCount] = useState<Record<string, number>>({});
  const [contractURL, setContractURL] = useState<string | undefined>(undefined);

  const { create } = useIssue();

  /**
   * Function for getting the horses
   */
  const cbHorses = useCallback(
    (options?: string[]) =>
      ownedHorses
        ?.filter(horse => {
          // Registration status changeable
          const registrationStatusChangeable = options.includes(
            'registrationStatusChangeable',
          )
            ? horse.horseOwnership.registrationStatusChangeable
            : true;

          // User representative
          const userRepresentative = options.includes('userRepresentative')
            ? horse.userRepresentative
            : true;

          // Mare
          const mare = options.includes('mare')
            ? horse.horseOwnership.gender.code === 'S'
            : true;

          // Should be included?
          return registrationStatusChangeable && userRepresentative && mare;
        })
        .map(horseOwnerhip => ({
          label: horseOwnerhip.horseOwnership.horse.name,
          value: horseOwnerhip.horseOwnership.horse.id.toString(),
        })),
    [ownedHorses],
  );

  /**
   * Function for getting the horses
   */
  const cbHorsesTraining = useCallback(
    () =>
      trainedHorses?.map(horseTrained => ({
        label: horseTrained.horse.name,
        value: horseTrained.horse.id.toString(),
      })),
    [trainedHorses],
  );

  const isHidden = useCallback(
    (
      field: FormField | Template['fields'][0],
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      values: FormState,
    ): boolean => {
      if (field.hidden) {
        return eval(`${field.hidden}`);
      }

      const option = template.fieldOptions?.find(option => option[field.name]);
      if (option && option[field.name]?.hidden) {
        return eval(`${option[field.name].hidden}`);
      }

      return false;
    },
    [template.fieldOptions],
  );

  const cbExportedHorses = useCallback(
    () =>
      exportedHorses?.map(horseExported => ({
        label: horseExported.name,
        value: horseExported.horseId.toString(),
      })),
    [exportedHorses],
  );

  const getHorseId = useCallback(
    (name: string): number => {
      if (
        name === 'horseInfo' ||
        name === 'mareInfo' ||
        name === 'horseExportInfo'
      ) {
        return horseId;
      }
      if (name === 'stallionInfo') {
        return stallionId;
      }
      if (name === 'horseSearchInfo') {
        return horseSearchId;
      }
      return 0;
    },
    [horseId, horseSearchId, stallionId],
  );

  const getField = useCallback(
    (
      field: string,
      values: FormState,
      setFieldValue: SetFieldValueType,
      resetForm: () => void,
    ): JSX.Element => {
      const def = fields.find(f => f.name === field);

      if (!def) {
        console.error('No field found', field);
        return null;
      }

      const options = template.fieldOptions.find(option => option[field])?.[
        def.name
      ];

      // Is the object hidden?
      if (isHidden(def, values)) {
        return null;
      }

      // Switchbutton
      if (def?.type === 'switchbutton') {
        return (
          <Box sx={{ gridColumn: [, , 'span 3'] }}>
            <SwitchButton
              name={def.name}
              on={def.options[0]}
              off={def.options[1]}
              defaultChecked={false}
              onChange={element => {
                if (def.name === 'horseSwitchButtonTemporaryExport') {
                  resetForm();
                }

                if (def.name === 'horseSwitchButtonStallion') {
                  setStallionId(0);
                  setFieldValue('stallion', undefined, true);
                }

                if (def.name !== 'horseSwitchButtonStallion') {
                  setHorseId(0);
                  setHorseSearchId(0);
                  setFieldValue('horse', undefined, true);
                  setFieldValue('trainer', undefined, true);
                }
                setFieldValue(def.name, element.target.checked, true);
                setFieldValue('horseInTraining', undefined, true);
                setFieldValue('horseOutOfTraining', undefined, true);
                setFieldValue('horseLocation', undefined, true);
                setFieldValue('horseImport', undefined, true);
              }}
            />
          </Box>
        );
      }

      // Select
      if (def?.type === 'select') {
        const selectOptions =
          (def.name === 'horse' || def.name === 'mare') && cbHorses(def.options)
            ? [{ label: 'Välj häst', value: '' }, ...cbHorses(def.options)]
            : def.name === 'horseTraining' && cbHorsesTraining()
              ? [{ label: 'Välj häst', value: '' }, ...cbHorsesTraining()]
              : def.name === 'horseImport' && cbExportedHorses()
                ? [{ label: 'Välj häst', value: '' }, ...cbExportedHorses()]
                : def.options?.includes('European')
                  ? [
                      { label: 'Välj land', value: '' },
                      ...europeanCountries
                        .filter(country =>
                          def.options?.includes('Exclude Sweden') &&
                          country.code === 'SE'
                            ? false
                            : true,
                        )
                        .map(country => ({
                          label: country.swedishName,
                          value: country.code,
                        }))
                        .sort((a, b) => a.label.localeCompare(b.label)),
                    ]
                  : def.selectOptions.map(option =>
                      typeof option === 'string'
                        ? { label: option, value: option }
                        : option,
                    );

        const onChange =
          def.name === 'horse' ||
          def.name === 'horseTraining' ||
          def.name === 'mare' ||
          def.name === 'horseImport'
            ? (element: React.ChangeEvent<HTMLSelectElement>) => {
                const id = Number.parseInt(element.target.value);
                setHorseId(id);
                setFieldValue(options.output ?? def.name, id);
              }
            : // Manual override for betackningResult, set "Ej tvilling" if "Föl" is selected
              def.name === 'betackningResult'
              ? (element: React.ChangeEvent<HTMLSelectElement>) => {
                  setFieldValue(
                    options?.output ?? def.name,
                    element.target.value,
                  );
                  setFieldValue(
                    'foalTwin',
                    element.target.value === 'Föl' ? 'Ej tvilling' : null,
                    true,
                  );
                }
              : undefined;
        return (
          <Box
            key={def.name}
            sx={{ flexGrow: 1, ...def.sx }}
            position={'relative'}
            pt={1}
          >
            <Select
              label={def.label}
              name={def.name}
              required={!def.notRequired}
              options={selectOptions}
              onChange={onChange}
            />
          </Box>
        );
      }

      if (def?.type === 'radio') {
        return (
          <Flex
            key={`${def.name}_flex`}
            flexDirection="row"
            justifyContent="start"
            alignItems="center"
            sx={{ gridColumn: ['span 1', , 'span 3'], gap: '20px' }}
          >
            {def.options?.map(option => (
              <Flex
                key={`${option}_box`}
                alignItems="center"
                justifyContent="start"
                flexDirection={[, , 'row']}
                flexWrap="nowrap"
                sx={{ gap: '10px' }}
              >
                <Input
                  key={option}
                  type="radio"
                  name={def.name}
                  value={option}
                  defaultChecked={option === def.options[0]}
                  size={16}
                  required={!def.notRequired}
                  sx={{ width: '16px' }}
                  onChange={e => {
                    setFieldValue(def.name, e.target.value);
                  }}
                />
                <Text fontSize={'14px'} fontWeight={'normal'}>
                  {option}
                </Text>
              </Flex>
            ))}
          </Flex>
        );
      }

      // Select
      if (def?.type === 'sex') {
        return (
          <Box key={def.name} sx={{ flexGrow: 1 }} position={'relative'} pt={1}>
            <Select
              label={def.label}
              name={def.name}
              onChange={e => {
                setFieldValue(def.name, e.target.value);
              }}
              required={!def.notRequired}
              options={['Välj kön', 'Hingst', 'Sto', 'Valack']
                .filter(value =>
                  def.options?.includes('excludeValack') && value === 'Valack'
                    ? false
                    : true,
                )
                .map(value => ({
                  label: value,
                  value,
                }))}
            />
          </Box>
        );
      }

      // Select
      if (def?.type === 'mareStatus') {
        return (
          <Box key={def.name} sx={{ flexGrow: 1 }} position={'relative'} pt={1}>
            <Select
              label={def.label}
              name={def.name}
              onChange={e => {
                setFieldValue(def.name, e.target.value);
              }}
              required={!def.notRequired}
              options={[
                'Välj status',
                'Maiden',
                'Gall',
                'Kastat',
                'Resorberat',
                'Ej betäckt föregående år',
                'Föl vid sidan',
                'Dräktig',
              ].map(value => ({
                label: value,
                value,
              }))}
            />
          </Box>
        );
      }

      if (def?.type === 'horseInfo') {
        const horseId = getHorseId(def.name);

        if (!horseId) {
          return null;
        }

        if (def.name === 'horseExportInfo') {
          return (
            <HorseExportInfo
              def={def}
              exportedHorse={exportedHorses.find(
                horse => horse.horseId === horseId,
              )}
              horseId={horseId}
              onReset={() => {
                setHorseId(0);
                setFieldValue('horseImport', undefined, true);
              }}
            />
          );
        }

        return (
          <HorseInfo
            def={def}
            onReset={() => {
              if (def.name === 'horseInfo' || def.name === 'mareInfo') {
                setHorseId(0);
              } else if (def.name === 'stallionInfo') {
                setStallionId(0);
              } else if (def.name === 'horseSearchInfo') {
                setHorseSearchId(0);
              } else if (def.name === 'horseImport') {
                setFieldValue('horseImport', undefined, true);
              }
            }}
            horseId={horseId}
            exportPersonName={template.id === 6}
          />
        );
      }

      if (def?.type === 'date') {
        return (
          <div>
            <Label htmlFor={def.name}>{def.label}</Label>
            <DatePicker
              autoComplete="anyrandomstring"
              disabled={false}
              name={def.name}
              maxDate={options?.max ? eval(`${options.max}`) : undefined}
              minDate={options?.min ? eval(`${options.min}`) : undefined}
            />
          </div>
        );
      }

      if (def?.type === 'label') {
        return (
          <Box
            key={def.name}
            sx={{
              flexGrow: 1,
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
              gap: '20px',
              gridColumn: ['span 1', , 'span 3'],
            }}
          >
            <H3 color="gray6" fontSize={['text', , 'lead']}>
              {def.label}
            </H3>
          </Box>
        );
      }

      if (def?.type === 'personInfo') {
        return <PersonInfo def={def} user={user} userData={userData} />;
      }

      if (def?.type === 'multiplePersons') {
        const showUser = options?.showUser
          ? options?.showUser === true || eval(options?.showUser)
          : false;

        const count = personCount[def.name] ?? 1;

        return (
          <Box
            key={def.name}
            width={'100%'}
            sx={{ gridColumn: ['span 1', , 'span 3'] }}
          >
            {showUser && (
              <Box
                width={'100%'}
                my={2}
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(3, 1fr)',
                  gap: '20px',
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Label>Namn</Label>
                  <Input
                    disabled
                    value={`${user.userInfo.firstName} ${user.userInfo.lastName}`}
                  />
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <Label>E-post</Label>
                  <Input
                    disabled
                    value={userData.changeableUserInformation.email}
                  />
                </Box>
              </Box>
            )}
            {[...Array(count)].map((e, i) => (
              <Box
                key={`${def.name}.${i + 1}`}
                width={'100%'}
                my={2}
                sx={{
                  display: 'grid',
                  gridTemplateColumns: ['repeat(1, 1fr)', , 'repeat(3, 1fr)'],
                  gap: '20px',
                  rowGap: ['10px', , '0px'],
                  paddingBottom: ['40px', , '0px'],
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Label htmlFor={`${options?.output ?? def.name}.${i}.name`}>
                    Namn
                  </Label>
                  <FormikInput
                    name={`${options?.output ?? def.name}.${i}.name`}
                  />
                </Box>
                {def.options?.includes('SSN') && (
                  <Box sx={{ flexGrow: 1 }}>
                    <Label htmlFor={`${options?.output ?? def.name}.${i}.ssn`}>
                      Personnummer
                    </Label>
                    <FormikInput
                      name={`${options?.output ?? def.name}.${i}.ssn`}
                    />
                  </Box>
                )}
                {!def.options?.includes('-email') && (
                  <Box sx={{ flexGrow: 1 }}>
                    <Label
                      htmlFor={`${options?.output ?? def.name}.${i}.email`}
                    >
                      E-post
                    </Label>
                    <FormikInput
                      name={`${options?.output ?? def.name}.${i}.email`}
                    />
                  </Box>
                )}
              </Box>
            ))}
            {(!options?.max || count < Number(options?.max)) && (
              <ButtonLink
                onClick={() => {
                  setPersonCount(prev => {
                    const newCount = { ...prev };
                    newCount[def.name] = (prev[def.name] ?? 1) + 1;
                    return newCount;
                  });
                }}
              >
                + Lägg till person
              </ButtonLink>
            )}
          </Box>
        );
      }

      if (def?.type === 'search') {
        return (
          <HorseSearch
            def={def}
            gender={
              def.name === 'stallionSearch'
                ? 'H'
                : def.name === 'mareSearch'
                  ? 'S'
                  : undefined
            }
            onSelect={horseId => {
              if (def.name === 'stallionSearch') {
                setStallionId(horseId);
              } else {
                setHorseSearchId(horseId);
              }
            }}
          />
        );
      }

      if (def?.type === 'nameWithSuffix') {
        return (
          <Box key={def.name} sx={{ flexGrow: 1, position: 'relative' }}>
            <Box
              sx={{
                display: 'grid',
                gap: '5px',
                gridTemplateColumns: '3fr 1fr',
              }}
            >
              <div>
                <Label htmlFor={def.name}>{def.label}</Label>
                <FormikInput
                  name={def.name}
                  required
                  maxLength={
                    options?.max
                      ? Number.parseInt(options?.max.toString())
                      : undefined
                  }
                />
                <Text
                  position={'relative'}
                  top={1}
                  fontSize="small"
                  mb={'-1px'}
                  bottom={0}
                  pb={0}
                  alignSelf={'baseline'}
                >
                  {def.description ?? <br />}
                </Text>
              </div>
              <div>
                <Label htmlFor={`${def.name}_suffix`}>Ev. suffix</Label>
                <FormikInput name={`${def.name}_suffix`} />
              </div>
            </Box>
          </Box>
        );
      }

      if (def?.type === 'info') {
        return (
          <Box sx={{ gridColumn: ['span 1', , 'span 3'] }}>
            <InfoBadge icon={InfoCircle} color={'blue'} message={def.label} />
          </Box>
        );
      }

      if (def?.type === 'br') {
        return <Box sx={{ ...def.sx }} />;
      }

      // Default is an input
      const output = options?.output
        ? {
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue(def.name, e.target.value);
              if (options.outputBefore) {
                const value = `${e.target.value} ${values[options.outputBefore] ?? ''}`;
                setFieldValue(options.output, value);
              } else if (options.outputAfter) {
                const value = `${values[options.outputAfter]} ${e.target.value ?? ''}`;
                setFieldValue(options.output, value);
              } else {
                setFieldValue(options.output, e.target.value);
              }
            },
          }
        : null;

      return (
        <Box
          key={def.name}
          sx={{ flexGrow: 1, position: 'relative', ...def.sx }}
        >
          <Label htmlFor={def.name}>{def.label}</Label>

          <FormikInput
            name={def.name}
            required
            max={options?.max}
            min={options?.min}
            pattern={options?.pattern}
            {...output}
          />
          <Text
            position={'relative'}
            top={1}
            fontSize="small"
            mb={'-1px'}
            bottom={0}
            pb={0}
            alignSelf={'baseline'}
          >
            {def.description ?? <br />}
          </Text>
        </Box>
      );
    },
    [
      template.fieldOptions,
      template.id,
      isHidden,
      cbHorses,
      cbHorsesTraining,
      cbExportedHorses,
      getHorseId,
      exportedHorses,
      user,
      userData,
      personCount,
    ],
  );

  const validate = useCallback(
    (values: FormState) => {
      const errors = {};
      template.fields
        .filter(f => !isHidden(f, values))
        .forEach(field => {
          field.fields.forEach(field => {
            const def = fields.find(f => f.name === field);

            if (!def) {
              console.error('No field found', field);
              return null;
            }

            const options = template.fieldOptions.find(
              option => option[field],
            )?.[def.name];

            // Is the object hidden?
            if (isHidden(def, values)) {
              return null;
            }

            const value = values[options?.output ?? def.name];

            // Text
            if (
              !def?.notRequired &&
              !options?.notRequired &&
              !value &&
              (def.type === 'text' || def.type === 'name')
            ) {
              errors[def.name] = 'Obligatoriskt';
            }

            // Pattern
            if (
              value &&
              def.type === 'text' &&
              def?.pattern &&
              !new RegExp(def?.pattern).test(value as string)
            ) {
              errors[def.name] = 'Fältet är inte korrekt ifyllt';
            }

            // Search
            if (!def?.notRequired && !value && def.type === 'search') {
              errors[def.name] = 'Obligatoriskt';
            }

            // Å-Ä-Ö for name
            if (
              def.type === 'name' &&
              value &&
              !/^[a-zA-ZåäöÅÄÖ ]+$/.test(value as string)
            ) {
              errors[def.name] = 'Endast A-Ö och mellanslag';
            }

            // Name with suffix
            if (!def?.notRequired && !value && def.type === 'nameWithSuffix') {
              errors[def.name] = 'Obligatoriskt';
            }

            // Select
            if (
              !def?.notRequired &&
              (!values[options?.output ?? def.name] ||
                values[options?.output ?? def.name] === 'Välj kön' ||
                values[options?.output ?? def.name] === 'Välj status') &&
              (def.type === 'select' ||
                def.type === 'sex' ||
                def.type === 'mareStatus')
            ) {
              errors[def.name] = 'Obligatoriskt';
            }

            // Date
            if (!def?.notRequired && !value && def.type === 'date') {
              errors[def.name] = 'Obligatoriskt';
            }
          });
        });

      return errors;
    },
    [isHidden, template.fieldOptions, template.fields],
  );

  // Get all boxes
  const boxes = useCallback(
    (
      values: FormState,
      setFieldValue: SetFieldValueType,
      resetForm: () => void,
    ) =>
      template.fields
        .filter(f => !isHidden(f, values))
        .map((field, index) => (
          <Step
            mb={5}
            mx={[0, , 2]}
            key={field.name}
            header={`${index + 1}. ${field.name}`}
            verticalSpacing={2}
            pb={2}
            width={'100%'}
          >
            <Text fontSize={'normal'}>{field.description}</Text>
            {field.conditions &&
              field.conditions
                .filter(condition => eval(`${condition.condition}`))
                .map(condition => (
                  <Box pt={1} key={condition.text}>
                    <InfoBadge
                      icon={InfoCircle}
                      color={'blue'}
                      message={<>{condition.text}</>}
                    />
                  </Box>
                ))}
            <Box
              py={2}
              px={0}
              sx={{
                display: 'grid',
                gridTemplateColumns: ['repeat(1, 1fr)', , 'repeat(3, 1fr)'],
                gap: '20px',
              }}
            >
              {field.fields.map(subField => (
                <Fragment key={subField}>
                  {getField(subField, values, setFieldValue, resetForm)}
                </Fragment>
              ))}
            </Box>
          </Step>
        )),
    [template.fields, isHidden, getField],
  );

  const getDescription = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (values: FormState): JSX.Element => {
      let description: JSX.Element = null;
      if (template.provider === 'scrive') {
        description = (
          <Text>
            Är du säker på att du vill skicka ärendet till signering?
            <br />
            <br />
          </Text>
        );
      } else {
        description = (
          <Text>
            Är du säker på att du vill skicka ärendet till Svensk Galopp för
            handläggning?
            <br />
            <br />
          </Text>
        );
      }
      return description;
    },
    [template.provider],
  );

  // TODO: Improve this maybe? Insecure?
  const getDefaultValue = useCallback(
    (name: string): string | undefined => {
      try {
        return eval(`${name}`);
      } catch (e) {
        return '';
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [template.id],
  );

  // Get initial values for the form
  const initialValues = useMemo(() => {
    // Templates that should not set personal number or full name
    const noIds = [7, 8];

    const initial: FormState = {
      template: template.id,
      senderId: user?.licenseId,
      firstName: userData?.basicUserInformation.firstName,
      lastName: userData?.basicUserInformation.lastName,
      email: userData?.changeableUserInformation.email,
      personalNumber: noIds.includes(template.id)
        ? null
        : user?.userInfo.idNumber,
      fullName: noIds.includes(template.id)
        ? null
        : `${userData?.basicUserInformation.firstName} ${userData?.basicUserInformation.lastName}`,
      phone:
        userData?.basicUserInformation.mobilePhoneNumber ??
        userData?.basicUserInformation.homePhoneNumber,
      address: userData?.basicUserInformation.address.streetAddress,
      zipCodeCity: `${userData?.basicUserInformation.address.postCode} ${userData?.basicUserInformation.address.city}`,
      importCountry: null,
      exportPersonCountry: template.id === 6 ? 'SE' : null,
    };

    const templateFields = template?.fields.flatMap(field => field.fields);
    fields
      .filter(field => templateFields?.includes(field.name))
      .forEach(field => {
        if (field.type === 'text') {
          initial[field.name] = getDefaultValue(field.defaultValue);
        }
        if (field.type === 'radio') {
          initial[field.name] = field.options[0];
        }
      });

    return { ...initial };
  }, [
    getDefaultValue,
    template?.fields,
    template.id,
    user?.licenseId,
    user?.userInfo.idNumber,
    userData?.basicUserInformation.address.city,
    userData?.basicUserInformation.address.postCode,
    userData?.basicUserInformation.address.streetAddress,
    userData?.basicUserInformation.firstName,
    userData?.basicUserInformation.homePhoneNumber,
    userData?.basicUserInformation.lastName,
    userData?.basicUserInformation.mobilePhoneNumber,
    userData?.changeableUserInformation.email,
  ]);

  // If there is no data, return null and we need login return null
  const needLogin = false;
  if (!userData && needLogin) {
    return null;
  }

  // Submit the form
  const onSubmit = async (values: FormState): Promise<void> => {
    const output = { ...values };

    // Remove exportReason if the horse is being imported
    if (template.id === 6 && values.horseSwitchButtonTemporaryExport) {
      delete output.exportReason;
    }

    const response = await create(template.provider, {
      templateUrl: `/tjanster/nytt?templateId=${template.id}`,
      senderId: user?.licenseId,
      actorType: template.actorType,
      email: userData?.changeableUserInformation.email as string,
      firstName: values.firstName as string,
      lastName: values.lastName as string,
      template: template.template?.toString(),
      values: output,
    });

    const data = await response.json();

    if (response.status !== 200 || data.status === 'error') {
      console.error({ response });
      return;
    }

    // Open new page with contract
    if (data.contract_url) {
      setContractURL(data.contract_url);
      window.open(data.contract_url, '_blank');
    }

    location.href = `/minasidor/arenden?new=true${
      template.newIssueButton ? `&newIssueButton=${template.id}` : ''
    }`;
  };

  return (
    <>
      <Box px={2} py={1} pb={0}>
        <FadedBox
          maxHeight={textOpen ? 'none' : `${toggleLimit}px`}
          overflowY="hidden"
          ml={0}
          pb={0}
          fadedBottom={!textOpen ? 50 : 0}
          fadedBg={theme.colors['white']}
          onClick={() => !textOpen && setTextOpen(!textOpen)}
        >
          <Text
            dangerouslySetInnerHTML={{
              __html: issueTemplate.description.replace(
                'a href=',
                `a target='_blank' href=`,
              ),
            }}
          />
        </FadedBox>
        <ButtonLink
          onClick={() => setTextOpen(!textOpen)}
          color="gray5"
          lineHeight="18px"
          fontSize="small"
          mt={textOpen ? 2 : 1}
          sx={{
            borderBottom: 'solid 1px',
            textTransform: 'uppercase',
          }}
        >
          {textOpen ? 'Läs mindre' : 'Läs mer'}
        </ButtonLink>
        {isLoggedIn(user) && (
          <Box pt={4} pb={4}>
            <InfoBadge
              icon={InfoCircle}
              color={'blue'}
              message={
                <>
                  {user?.userInfo.firstName} {user?.userInfo.lastName}
                  {', '}
                  {userData.changeableUserInformation.email}
                  {', '}
                  {userData.basicUserInformation?.mobilePhoneNumber ??
                    userData.basicUserInformation?.homePhoneNumber}
                </>
              }
            />
          </Box>
        )}
      </Box>
      <Formik<FormState>
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={true}
        validate={validate}
      >
        {({
          values,
          setFieldValue,
          setSubmitting,
          setFieldTouched,
          resetForm,
          isSubmitting,
          isValid,
          dirty,
        }) => (
          <>
            <Prompt
              when={dirty}
              message="Du har osparade ändringar, säkert att du vill lämna sidan?"
            />
            <Alert
              shouldCloseOnOverlayClick={false}
              closeModal={() => {
                setOpen(false);
              }}
              open={open}
              size="medium"
              action="Skicka"
              title={issueTemplate.title}
              icon={ExclamationTriangle}
              loading={isSubmitting}
              onAction={async () => {
                setSubmitting(true);
                await onSubmit(values);
                setOpen(false);
                setSubmitting(false);
              }}
              description={getDescription(values)}
              colorScheme="primary"
              iconProps={{
                size: 14,
                mb: '2px',
                mr: '1px',
              }}
            ></Alert>
            {template.description && (
              <Box mb={4} ml={2}>
                <H3>{template.description}</H3>
              </Box>
            )}
            {boxes(values, setFieldValue, resetForm)}
            {template.confirm && (
              <Flex mb={4} ml={2} justifyContent={'start'} flexWrap="nowrap">
                <Checkbox
                  name="confirm"
                  onChange={e => {
                    setFieldValue('confirm', e.target.checked);
                  }}
                />
                <Text>
                  {template.confirm.text}
                  <ExternalLink
                    as="a"
                    ml={'2px'}
                    style={{ display: 'inline-flex' }}
                    href={template.confirm.link}
                    target="_blank"
                  >
                    {template.confirm.linkText}
                    <Box>
                      <Icon
                        size={12}
                        ml={'3px'}
                        color="blue"
                        as={ExternalLinkAlt}
                      />
                    </Box>{' '}
                  </ExternalLink>
                </Text>
              </Flex>
            )}
            {values['betackningResult'] === 'Föl' && (
              <Box mb={4} ml={2} mr={-2}>
                <InfoBadge
                  icon={InfoCircle}
                  color={'blue'}
                  message={
                    'När du skickar in ärendet ansöker du om inregistrering av fölet.'
                  }
                />
              </Box>
            )}

            <Box mb={4} ml={2}>
              <Button
                disabled={
                  !!contractURL || !isValid || !dirty || template.confirm
                    ? !values.confirm
                    : false
                }
                onClick={e => {
                  const errors = validate(values);
                  if (Object.keys(errors).length > 0) {
                    Object.keys(errors).forEach(key => {
                      setFieldTouched(key, true, false);
                    });
                  } else {
                    e.preventDefault();
                    setOpen(true);
                  }
                }}
              >
                Skicka
              </Button>
              {contractURL && (
                <a
                  href={contractURL}
                  style={{ paddingLeft: '20px' }}
                  target="_blank"
                  rel="noreferrer"
                >
                  Öppna avtal för att signera
                </a>
              )}
            </Box>
          </>
        )}
      </Formik>
    </>
  );
}
