import 'styled-components';

import { useGenerateInvoicePdfLink } from '@apis';
import ErrorPage from '@components/ErrorPage';
import useNumericParams from '@hooks/useNumericParams';
import Box from '@primitives/Box';
import Loading from '@primitives/Loading';
import { TriangleExclamation as ExclamationTriangle } from '@styled-icons/fa-solid';
import React, { useEffect, useState } from 'react';

function GenerateInvoiceUrlAndRedirect(): JSX.Element {
  const { ownerId, invoiceNumber, index } = useNumericParams();
  const [error, setError] = useState<string>();

  const { action, loading } = useGenerateInvoicePdfLink(
    ownerId,
    invoiceNumber,
    index,
  );

  useEffect(() => {
    const runAction = async (): Promise<void> => {
      const { body } = await action(undefined);

      if (body?.errorMessage) {
        setError(body.errorMessage);
      } else if (body?.url) {
        window.location.href = body.url;
      } else {
        setError('Oväntat fel inträffade');
      }
    };

    runAction();
  }, [action]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <ErrorPage
        headline="Oväntat fel"
        header="Ett oväntat fel har inträffat"
        content={
          <Box
            as="pre"
            mx="auto"
            maxWidth="300px"
            bg="gray1"
            border="solid 1px #ccc"
            p={4}
          >
            {error}
          </Box>
        }
        icon={ExclamationTriangle}
      />
    );
  }

  return null;
}

export default GenerateInvoiceUrlAndRedirect;
